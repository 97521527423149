.swal2-backdrop-show{
    z-index: 1;
}
.swal2-container{
    z-index: 999 !important;
}
.css-8ouju4 {
    color: rgb(33, 43, 54) !important;
}
.css-1n6fgnw{
    z-index: 999 !important;
}
.swal2-styled.swal2-confirm{
    background-color: #18ab59 !important;
}

#swal2-title{
    font-size: 16px;   
}

.swal-button--confirm {
    background-color: #18ab59 !important;
}
.grecaptcha-badge { visibility: hidden; }

.css-rwtjxp-MuiPaper-root-MuiAppBar-root{
    z-index: 0;
}

.ant-btn-primary{
    background-color: #18ab59 !important;
}